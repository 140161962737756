import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./home/Home";
import VerificationScreen from "./verification/Verification";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<HomeScreen />}/>
          {/* <Route path="privacy" element={<PrivacyScreen />}/> */}
          <Route path="verify" element={<VerificationScreen />}/>
          <Route path="*" element={<HomeScreen />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
