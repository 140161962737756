import { Endpoint } from ".";

export class UserAPI {
    static verify(method: "POST", token: string): Endpoint {
        return {
            path: "/user/verify",
            method: method,
            body: {
                jwt: token
            }
        }
    }
}
