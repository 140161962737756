
export const APIClientConfig = {
    apiVersion: "v1.0",
    baseUrl: {
        local: {
            protocol: "http://",
            ip: "192.168.2.5",
            port: "6000",
        },
        release: "https://rsmpzila5c.execute-api.ap-southeast-2.amazonaws.com/Prod/"
    },
}