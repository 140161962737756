import "./Home.css"

const HomeScreen = () => {
    return (
        <div className="App">
            <header className="App-header">
                <img src="./assets/img/mailchimp-inverted-large.png" width={650} />

                <h1
                    style={{
                        fontFamily: "arista",
                        fontSize: 36,
                        color: "#FF8C00",
                        marginTop: 16,
                    }}
                >
                    Off-market Listings
                </h1>

                <h2>Coming soon</h2>

                <div
                    style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <a href="https://play.google.com/store/apps/details?id=com.proptechgroup.preview">
                        <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            style={{ height: 100 }}
                        />
                    </a>
                    <a href="https://apps.apple.com/au/app/preview-off-market-listings/id1576136570?itsct=apps_box_promote_link&itscg=30200">
                        <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1537920000&h=b39c2a88c225387d6de26bb08b779475"
                            alt="Download on the App Store"
                            style={{ width: 200, height: 100 }}
                        ></img>
                    </a>
                </div>
            </header>
        </div>
    )
}

export default HomeScreen
