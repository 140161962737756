import { APIClientConfig, Endpoint } from "."
import { print } from "../utils"

export class APIClient {

    constructor (
        private variant: "local" | "prod" = 
            process.env.NODE_ENV == "development" ? "local" : "prod"
    ) {}

    private get baseUrl(): string {
        switch (this.variant) {
            case "local":
                return (
                    "" +
                    APIClientConfig.baseUrl.local.protocol +
                    APIClientConfig.baseUrl.local.ip +
                    ":" +
                    APIClientConfig.baseUrl.local.port +
                    "/" +
                    APIClientConfig.apiVersion
                )

            case "prod":
                return APIClientConfig.baseUrl.release + APIClientConfig.apiVersion
        }
    }

    async call<T extends object>(request: Endpoint, signal?: AbortSignal): Promise<Response | null> {
        var url = this.baseUrl + request.path

        if (request.params) {
            let paramsString = Object.entries(request.params)
                .filter(object => object[1])
                .map(object => {
                    if (object[1] instanceof Date) {
                        object[1] = object[1].toISOString()
                    }
                    return `${encodeURIComponent(object[0])}=${encodeURIComponent(object[1])}`
                })
                .join("&")

            if (paramsString) {
                url += "?"
                url += paramsString
            }
        }

        print(`==> ${request.method} ${url}`)

        let options: RequestInit = {
            method: request.method,
            headers: {
                "Content-Type": "application/json",
            },
            signal: signal,
            mode: "cors"
        }

        if (request.body) {
            options.body = JSON.stringify(request.body)
            print(request.body)
        }

        let response: Response

        let result = {}

        try {
             response = await fetch(url, options)
        } catch (e) {
            print(`NO_RESPONSE <== ${request.method} ${url}`)
            return null
        }

        try {
            result = await response.json()
        } catch (e) {
            print(e)
        }

        print(`${response.status} <== ${request.method} ${url}`)
        print(result)

        return response
    }
}