import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { APIClient, UserAPI } from "../http"
import "./Verification.css"

interface VerificationScreenProps {}

const VerificationScreen = (props: VerificationScreenProps) => {

    const [ params ] = useSearchParams()

    const [ verificationType, setVerficationType] = useState<"email" | "mobile" | undefined>(undefined)
    const [ responseCode, setResponseCode] = useState<number | undefined | null>(undefined)

    useEffect(() => {
        asyncInit()
    }, [])

    const asyncInit = async () => {
        const token = params.get("token")

        // TODO Enable mobile authentication
        setVerficationType("email")

        if (token) {
            const request = UserAPI.verify("POST", token)
            const client = new APIClient()
            const response = await client.call(request)
            setResponseCode(response?.status ?? null)

        } else {
            setResponseCode(400)
        }
    }

    const LoadingView = ({title}: {title: string}) => {
        return  (
            <div className="Verification-bg">
                <h1>{title}</h1>
                <CircularProgress style={{color: "white"}} />
            </div>
        )
    }

    const ResponseView = ({title, subtitle}: {title: string, subtitle: string}) => {
        return (
            <div className="Verification-bg">
                <h1>{title}</h1>
                <h1>{subtitle}</h1>
            </div>
        )
    }
    
    if (responseCode !== undefined) {

        if (responseCode === null) {
            return <ResponseView title="Unexpected Error" subtitle="Please try refreshing the page" />

        } else if (responseCode != 201) {
            return <ResponseView title="Hmmm" subtitle="There was an issue verifying your token" />

        } else {
            return <ResponseView title="Thank you!" subtitle="Your account has been verified" />
        }

    } else if (verificationType) {

        let type = ""

        switch (verificationType) {
            case "email": type = "email address"; break;
            case "mobile": type = "phone number"; break;
        }

        return <LoadingView title={`Verifying your ${type}`} />

    } else {
        return <LoadingView title={`Verifying your token`} />
    }

}

export default VerificationScreen